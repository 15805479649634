import { gql } from '@apollo/client';
export const loginQuery = gql`
    mutation authenticate($email: String!, $password: String!) {
        authenticate(serviceName: "password", params: { password: $password, user: { email: $email } }) {
            user {
                emails {
                    address
                }
                username
                firstName
                lastName
            }
            tokens {
                accessToken
                refreshToken
            }
            user {
                phone
            }
        }
    }
`;

export const viewerQuery = gql`
    query viewer {
        viewer {
            _id
            userId
            addressBook {
                edges {
                    node {
                        _id
                        address1
                        address2
                        city
                        company
                        country
                        fullName
                        isBillingDefault
                        isShippingDefault
                        phone
                        postal
                        region
                    }
                }
            }
            emailRecords {
                address
            }
            name
            primaryEmailAddress
            UserRole
            branchInfo {
                _id
                name
            }
        }
    }
`;

export const ChangePassword = gql`
    mutation updateRiderAccountPassword($newPassword: String!, $riderId: ID!, $email: String!) {
        updateRiderAccountPassword(
            input: { newPassword: $newPassword, passwordString: $newPassword, riderId: $riderId, riderEmail: $email }
        )
    }
`;

export const addBranch = gql`
    mutation CreateBranch(
        $name: String!
        $address: String!
        $phoneNumber1: String!
        $phoneNumber2: String!
        $phoneNumber3: String!
        $City: String!
        $taxID: ID!
        $deliveryStatus: Boolean!
        $Sector: String!
        $Timing: String!
        $Longitude: Float!
        $Latitude: Float!
    ) {
        createBranch(
            input: {
                name: $name
                address: $address
                phoneNumber1: $phoneNumber1
                phoneNumber2: $phoneNumber2
                phoneNumber3: $phoneNumber3
                City: $City
                Sector: $Sector
                Timing: $Timing
                taxID: $taxID
                deliveryStatus: $deliveryStatus
                Longitude: $Longitude
                Latitude: $Latitude
            }
        ) {
            _id
            name
            address
            phoneNumber1
            taxInfo {
                Cash
                Card
                Region
            }
        }
    }
`;

export const getAllBranch = gql`
    query Branches {
        branches {
            _id
            name
            address
            phoneNumber1
            phoneNumber2
            phoneNumber3
            deliveryStatus
            taxID
            City
            Sector
            Timing
            Latitude
            Longitude
            createdAt
            deliveryArea
            taxInfo {
                Card
                Cash
            }
        }
    }
`;

export const getAllBranchPagination = gql`
    query Branches($rowPerPage: ConnectionLimitInt!, $offset: Int!) {
        branchesPagination(first: $rowPerPage, offset: $offset) {
            totalCount
            nodes {
                _id
                name
                address
                phoneNumber1
                phoneNumber2
                phoneNumber3
                deliveryStatus
                taxID
                City
                Sector
                Timing
                Latitude
                Longitude
                createdAt
                deliveryArea
                taxInfo {
                    Card
                    Cash
                }
            }
        }
    }
`;

export const deleteBranch = gql`
    mutation DeleteBranch($_id: ID!) {
        deleteBranch(_id: $_id)
    }
`;

export const updateBranch = gql`
    mutation UpdateBranchData(
        $_id: ID!
        $name: String!
        $address: String!
        $phoneNumber1: String!
        $phoneNumber2: String!
        $phoneNumber3: String!
        $taxID: ID!
        $deliveryStatus: Boolean!
        $City: String!
        $Sector: String!
        $Timing: String!
        $Longitude: Float!
        $Latitude: Float!
    ) {
        updateBranchData(
            _id: $_id
            input: {
                name: $name
                address: $address
                phoneNumber1: $phoneNumber1
                phoneNumber2: $phoneNumber2
                phoneNumber3: $phoneNumber3
                taxID: $taxID
                deliveryStatus: $deliveryStatus
                City: $City
                Sector: $Sector
                Timing: $Timing
                Longitude: $Longitude
                Latitude: $Latitude
            }
        ) {
            _id
            name
            address
            phoneNumber1
        }
    }
`;

export const getReports = gql`
    query GenerateOrderReport(
        $rowPerPage: ConnectionLimitInt!
        $offset: Int!
        $searchQuery: String!
        $deliveryTime: Float
        $fromDate: DateTime
        $toDate: DateTime
        $branchID: String
        $riderID: String
        $isManual: Boolean
    ) {
        generateOrderReport(
            first: $rowPerPage
            offset: $offset
            deliveryTime: $deliveryTime
            fromDate: $fromDate
            toDate: $toDate
            branches: $branchID
            riderID: $riderID
            sortOrder: desc
            isManual: $isManual
            searchQuery: $searchQuery
        ) {
            totalCount
            nodes {
                OrderID
                riderInfo {
                    firstName
                    lastName
                }
                riderOrderAmount
                orderInfo {
                    payments {
                        finalAmount
                        tax
                        totalAmount
                    }
                }
                createdAt
                branchCity
                OrderStatus
                deliveryTime
                startTime
                endTime
                branchInfo {
                    name
                }
                customerInfo {
                    address1
                    fullName
                }
                kitchenOrderIDInfo {
                    kitchenOrderID
                }
                rejectionReason
                orderDetailTime {
                    prepTime
                    deliveryTime
                }
            }
        }
    }
`;

export const getRiderReports = gql`
    query getRiderReport($branchID: ID, $riderID: ID, $PageNumber: Int, $itemPerPage: Int, $startDate: DateTime, $endDate: DateTime) {
        getRiderReport(
            branchId: $branchID
            riderID: $riderID
            PageNumber: $PageNumber
            itemPerPage: $itemPerPage
            startDate: $startDate
            endDate: $endDate
        ) {
            totalPages
            RiderReport {
                _id
                totalOrders
                averageDeliveryTime
                cancelOrders
                completeOrder
                completeInTimeOrder
                totalActiveTime
                totalEarning
                totalManualOrders
                totalCustomerOrders
                riderInfo {
                    firstName
                    lastName
                    phone
                }
            }
        }
    }
`;
//  RiderOrderID
export const getOrderByDriverIdQuery = gql`
    query getRiderOrderByRiderId($id: ID!) {
        getRiderOrderByRiderId(id: $id) {
            startTime
            endTime
            OrderStatus
            OrderID
            kitchenOrderID
            kitchenOrderIDInfo {
                kitchenOrderID
            }
            deliveryTime
            createdAt
        }
    }
`;

// export const getOrderByDriverIdQuery = gql`
//     query getOrderById($id: ID!) {
//         getOrderById(id: $id) {
//             startTime
//             endTime
//             OrderStatus
//             OrderID
//             kitchenOrderID
//             kitchenOrderIDInfo {
//                 kitchenOrderID
//             }
//             deliveryTime
//             createdAt
//         }
//     }
// `;
export const createUserQuery = gql`
    mutation createUser(
        $firstName: String!
        $lastName: String!
        $phone: String!
        $email: String!
        $password: String!
        $UserRole: UserRoleValue!
    ) {
        createUser(
            user: { firstName: $firstName, lastName: $lastName, email: $email, phone: $phone, password: $password, UserRole: $UserRole }
        ) {
            userId
        }
    }
`;

export const getAllPaginatedUsers = gql`
    query getAllPaginatedUsers($rowPerPage: ConnectionLimitInt!, $offset: Int!, $searchQuery: String) {
        getAllPaginatedUsers(first: $rowPerPage, offset: $offset, searchQuery: $searchQuery, sortOrder: desc) {
            pageInfo {
                hasNextPage
                endCursor
                hasPreviousPage
                startCursor
            }
            totalCount
            nodes {
                UserRole
                userId
                username
                firstName
                lastName
                primaryEmailAddress
                phone
                branchInfo {
                    _id
                    name
                }
            }
        }
    }
`;
export const getAllCustomers = gql`
    query getAllCustomers($rowPerPage: ConnectionLimitInt!, $offset: Int!, $searchQuery: String) {
        getAllCustomers(first: $rowPerPage, offset: $offset, searchQuery: $searchQuery, sortOrder: desc) {
            totalCount
            nodes {
                userId
                firstName
                lastName
                primaryEmailAddress
                phone
            }
        }
    }
`;

export const deleteSingleUser = gql`
    mutation DeleteUser($userId: String!) {
        deleteUser(userId: $userId)
    }
`;

export const assignBranchToUser = gql`
    mutation AssignBranchtoUser($userId: ID!, $branchId: String!) {
        assignBranchtoUser(userID: $userId, branches: $branchId) {
            branches
        }
    }
`;

export const changeRoleOfUser = gql`
    mutation UpdateUserRole($UserRole: UserRoleValue!, $userId: String!) {
        updateUserRole(UserRole: $UserRole, userId: $userId)
    }
`;
export const changeBranchOfUser = gql`
    mutation UpdateAccountAdmin($userId: ID!, $branchId: String!) {
        updateAccountAdmin(userID: $userId, branches: $branchId) {
            _id
            branches
        }
    }
`;

export const generateKitchenReport = gql`
    query GenerateKitchenReport($rowPerPage: ConnectionLimitInt!, $offset: Int!, $branchID: String, $startDate: String, $endDate: String) {
        generateKitchenReport(
            first: $rowPerPage
            offset: $offset
            branchID: $branchID
            startDate: $startDate
            endDate: $endDate
            sortOrder: desc
        ) {
            pageInfo {
                hasNextPage
                endCursor
                hasPreviousPage
                startCursor
            }
            totalCount
            nodes {
                id
                _id
                startTime
                endTime
                createdAt
                updatedAt
                branchID
                orderIdResolver {
                    orderId
                }
                payments {
                    finalAmount
                    tax
                    totalAmount
                }
                email
                kitchenOrderID
                status
                branches
                username
                OrderStatus
                createdAt
                riderOrderInfo {
                    _id
                    startTime
                    endTime
                }
                riderInfo {
                    userId
                    _id
                    firstName
                    lastName
                    phone
                }
                fulfillmentGroups {
                    selectedFulfillmentOption {
                        fulfillmentMethod {
                            fulfillmentTypes
                        }
                    }
                    items {
                        nodes {
                            _id
                            quantity
                            optionTitle
                            title
                            variantTitle
                            optionTitle
                            attributes {
                                label
                                value
                            }
                        }
                    }
                }
                notes {
                    content
                    createdAt
                }
                payments {
                    billingAddress {
                        fullName
                        phone
                    }
                }
                deliveryTime
                branchTimePickup {
                    branchOrderTime
                }
                customerInfo {
                    address1
                }
                branchInfo {
                    name
                }
            }
        }
    }
`;

export const getAllOrdersOptimized = gql`
    query getKitchenReportOptimized($branchID: String, $startDate: String, $endDate: String, $type: String) {
        getKitchenReportOptimized(branchID: $branchID, startDate: $startDate, endDate: $endDate, type: $type) {
            id
            _id
            startTime
            endTime
            createdAt
            updatedAt
            branchID
            isGuestUser
            transferFromBranchInfo {
                _id
                name
            }
            summary {
                discountTotal {
                    amount
                }
            }
            payments {
                finalAmount
                tax
                totalAmount
            }
            email
            kitchenOrderID
            status
            branches
            username
            OrderStatus
            createdAt
            riderOrderInfo {
                _id
                startTime
                endTime
            }
            riderInfo {
                userId
                _id
                firstName
                lastName
                phone
            }
            fulfillmentGroups {
                selectedFulfillmentOption {
                    fulfillmentMethod {
                        fulfillmentTypes
                    }
                }
                items {
                    nodes {
                        _id
                        quantity
                        optionTitle
                        title
                        variantTitle
                        optionTitle
                        attributes {
                            label
                            value
                        }
                        price {
                            amount
                        }
                    }
                }
            }
            notes {
                content
                createdAt
            }
            payments {
                billingAddress {
                    fullName
                    phone
                }
            }
            deliveryTime
            branchTimePickup {
                branchOrderTime
            }
            customerInfo {
                address1
            }
            branchInfo {
                _id
                name
            }
            paymentMethod
            placedFrom
            isPaid
        }
    }
`;

export const getAllOrders = gql`
    query GetKitchenReport($branchID: String, $startDate: String, $endDate: String) {
        getKitchenReport(branchID: $branchID, startDate: $startDate, endDate: $endDate) {
            id
            _id
            startTime
            endTime
            createdAt
            updatedAt
            branchID
            summary {
                discountTotal {
                    amount
                }
            }
            payments {
                finalAmount
                tax
                totalAmount
            }
            email
            kitchenOrderID
            status
            branches
            username
            OrderStatus
            createdAt
            riderOrderInfo {
                _id
                startTime
                endTime
            }
            riderInfo {
                userId
                _id
                firstName
                lastName
                phone
            }
            fulfillmentGroups {
                selectedFulfillmentOption {
                    fulfillmentMethod {
                        fulfillmentTypes
                    }
                }
                items {
                    nodes {
                        _id
                        quantity
                        optionTitle
                        title
                        variantTitle
                        optionTitle
                        attributes {
                            label
                            value
                        }
                    }
                }
            }
            notes {
                content
                createdAt
            }
            payments {
                billingAddress {
                    fullName
                    phone
                }
            }
            deliveryTime
            branchTimePickup {
                branchOrderTime
            }
            customerInfo {
                address1
            }
            branchInfo {
                _id
                name
            }
        }
    }
`;

export const getAllRider = gql`
    query GetAllRiders {
        getAllRiders {
            userId
            _id
            picture
            firstName
            lastName
            currentStatus
            emailRecords {
                address
            }
            phone
            branchInfo {
                name
                phoneNumber1
                Latitude
                Longitude
                City
                Sector
                Timing
                Description
            }
        }
    }
`;

export const getAllRiders = gql`
    query GetAllRiders {
        getAllRiders {
            userId
            _id
            firstName
            lastName
        }
    }
`;

export const updateOrder = gql`
    mutation updateOrder($orderId: ID!, $status: String!) {
        updateOrder(input: { orderId: $orderId, status: $status }) {
            order {
                _id
            }
        }
    }
`;
// $branches: ID!
// branches: $branches
export const AssignRiderToOrder = gql`
    mutation CreateRiderOrder(
        $startTime: DateTime!
        $OrderID: String!
        $riderID: String!
        $OrderStatus: OrderStatusValue!
        $branches: ID!
        $riderOrderAmount: Float
    ) {
        createRiderOrder(
            orders: {
                startTime: $startTime
                OrderID: $OrderID
                riderID: $riderID
                OrderStatus: $OrderStatus
                branches: $branches
                riderOrderAmount: $riderOrderAmount
                isManual: false
            }
        ) {
            _id
            OrderID
        }
    }
`;
export const AddTaxData = gql`
    mutation CreateBranch($Label: String!, $Cash: Float!, $Card: Float!, $Region: String!) {
        createTax(input: { Label: $Label, Cash: $Cash, Card: $Card, Region: $Region }) {
            _id
            Label
            Cash
            Card
            Region
        }
    }
`;

export const getTaxesPagination = gql`
    query ($rowPerPage: ConnectionLimitInt!, $offset: Int!) {
        getAllTaxData(first: $rowPerPage, offset: $offset) {
            totalCount
            nodes {
                _id
                Label
                Region
                Cash
                Card
                createdAt
                updatedAt
            }
        }
    }
`;
export const getTaxes = gql`
    query {
        getAlltaxData {
            _id
            Label
            Cash
            Card
            Region
        }
    }
`;

export const deleteTaxes = gql`
    mutation deleteTax($id: ID!) {
        deleteTax(_id: $id)
    }
`;

export const updateTaxes = gql`
    mutation updateTax($id: ID!, $Label: String!, $Region: String!, $Cash: Float!, $Card: Float!) {
        updateTax(_id: $id, Label: $Label, Cash: $Cash, Card: $Card, Region: $Region) {
            _id
            Label
            Region
            Cash
            Card
        }
    }
`;
export const getReportState = gql`
    query getRiderOrderReportStat($branchID: ID!, $fromDate: DateTime, $toDate: DateTime) {
        getRiderOrderReportStat(input: { branchId: $branchID, startDate: $fromDate, endDate: $toDate }) {
            totalOrders
            deliveredOrders
            rejectedOrder
            inProgressOrder
        }
    }
`;

export const cancelOrder = gql`
    mutation cancelCustomerOrder($orderId: ID!, $reason: String!) {
        cancelCustomerOrder(input: { orderID: $orderId, cancelOrderReason: $reason }) {
            order {
                _id
                rejectionReason
                status
            }
        }
    }
`;

export const transferOrderBranch = gql`
    mutation transferOrder($orderID: String!, $transferTo: String!, $transferFrom: String!) {
        transferOrder(input: { orderID: $orderID, transferTo: $transferTo, transferFrom: $transferFrom }) {
            _id
            branchID
        }
    }
`;
